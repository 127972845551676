import React, { useMemo, useState } from 'react';
import { faCalculator, faEdit } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@spglobal/react-components';
import { Size } from '@spglobal/koi-helpers';
import { TRASH } from '@spglobal/koi-icons';
import UserContext from 'context/UserContext';
import { getRole } from 'utils/user';
import {
    UNCATEGORIZED,
    VIEW,
    RENAME,
    DELETE,
    ALLOWED_SUPER_ADMIN,
    AGGREGATE,
} from '../../utils/constants';
import DataTableGrid from '../DataTable/DataTableGrid/DataTableGrid';

const getFoldersTableColumns = (hideActions, isViewCreateEdit, aggregationDisabled, user) => {
    const columns = [
        {
            headerName: 'Folder Names',
            field: 'asset_folder_name',
            sortable: false,
            flex: 1,
            cellRenderer: 'folderNamesRenderer',
            checkboxSelection: isViewCreateEdit,
            headerCheckboxSelection: isViewCreateEdit,
        },
        {
            headerName: '#Assets',
            field: 'asset_count',
            sortable: false,
            flex: 1,
            cellRenderer: 'assetsRenderer',
        },
        {
            headerName: '#Folder ID',
            field: 'asset_folder_id',
            sortable: false,
            flex: 1,
            cellRenderer: 'folderIdRenderer',
        },
    ];

    if (!hideActions) {
        columns.push({
            headerName: 'Actions',
            field: 'action',
            sortable: false,
            flex: 0.5,
            cellRenderer: 'actionsRenderer',
            cellRendererParams: {
                aggregationDisabled,
                user,
            },
            width: 80,
            maxWidth: 80,
            cellStyle: { justifyContent: 'center', alignItems: 'baseline' },
            cellClass: 'cli-action-cell',
        });
    }

    return columns;
};

const FoldersDataTable = (props) => {
    const {
        tableData,
        actionCallback,
        isLoading,
        totalCount,
        hideActions = false,
        isViewCreateEdit = false,
        selectedRowIDs,
        pageData,
        onPageDataChange,
        handleRowsSelection,
        aggregationDisabled = false,

    } = props;
    const [gridApi, setGridApi] = useState(null);

    const handleRowClick = ({ event, data }) => {
        event.stopPropagation();
        if (data.hasOwnProperty('is_assigned') ? data?.is_assigned : true) {
            actionCallback?.(VIEW, data);
        }
    };
    const { user } = React.useContext(UserContext);

    const handlePageSizeChange = (newPageSize) => {
        const pageInfo = {
            ...pageData,
            page: 0,
            clicked: true,
            rowsPerPage: newPageSize,
        };
        onPageDataChange(pageInfo);
    };

    const handlePageChange = (newPageIndex) => {
        const pageInfo = {
            ...pageData,
            page: newPageIndex,
            clicked: true,
            rowsPerPage: pageData?.rowsPerPage,
        };
        onPageDataChange(pageInfo);
    };

    const handleGridReady = (e) => {
        setGridApi(e?.api);
    };

    const handleRowDataChanged = useMemo(() => {
        gridApi?.forEachNode((node) => {
            const isSelectedRow = selectedRowIDs?.includes(node?.data?.asset_folder_id);
            node?.setSelected(isSelectedRow);
        });
    }, [tableData, gridApi, handleGridReady, selectedRowIDs, pageData, isLoading]);

    const isRowDisabled = (rowData) =>
        rowData.hasOwnProperty('is_assigned') && !rowData?.is_assigned;

    const folderNamesCellRenderer = ({ data }) => {
        const rowData = data || {};

        return (
            <div
                className={
                    isRowDisabled(data) ? 'spg-text-secondary' : 'spg-align-center spg-flex-row'
                }
            >
                {rowData?.asset_folder_name === UNCATEGORIZED ? (
                    <>
                        Uncategorized <span className="spg-text-italic">(Default)</span>
                    </>
                ) : (
                    rowData.asset_folder_name
                )}
            </div>
        );
    };

    const assetsCellRenderer = ({ data }) => (
        <span className={isRowDisabled(data) ? 'spg-text-secondary' : ''}>{data.asset_count}</span>
    );

    const folderIdCellRenderer = ({ data }) => (
        <span className={isRowDisabled(data) ? 'spg-text-secondary' : ''}>
            {data.asset_folder_id === 0 ? <>NA</> : data.asset_folder_id}
        </span>
    );

    const actionsCellRenderer = (actionsCellProps) => {
        const role = getRole(
            actionsCellProps.user && actionsCellProps.user.SPGGroups
                ? actionsCellProps.user.SPGGroups
                : [],
        );

        const handleActionsClick = (event, actionType, params) => {
            event.stopPropagation();
            actionCallback(actionType, params?.data);
        };

        const isActionsDisabled = !!(
            isRowDisabled(actionsCellProps?.data) ||
            actionsCellProps?.data?.asset_folder_name === UNCATEGORIZED
        );

        const isDeleteActionDisabled = !!(
            (actionsCellProps?.data?.asset_folder_name === UNCATEGORIZED) &&
            (actionsCellProps?.data?.asset_count == 0)
        );

        return (
            <div className="spg-d-flex spg-align-center">
                <IconButton
                    aria-label="rename-folder"
                    disabled={isActionsDisabled}
                    size={Size.SMALL}
                    icon={faEdit}
                    onClick={(e) => handleActionsClick(e, RENAME, actionsCellProps)}
                    title="Rename"
                />

                <IconButton
                    aria-label="delete-folder"
                    disabled={isDeleteActionDisabled}
                    size={Size.SMALL}
                    icon={TRASH}
                    onClick={(e) => handleActionsClick(e, DELETE, actionsCellProps)}
                    title="Delete"
                />
                {role && ALLOWED_SUPER_ADMIN.includes(role) && (
                    <IconButton
                        aria-label="aggregate-folder"
                        disabled={actionsCellProps.aggregationDisabled}
                        size={Size.SMALL}
                        icon={faCalculator}
                        onClick={(e) => handleActionsClick(e, AGGREGATE, actionsCellProps)}
                        title="Aggregate"
                    />
                )}
            </div>
        );
    };

    const frameworkComponents = {
        folderNamesRenderer: folderNamesCellRenderer,
        assetsRenderer: assetsCellRenderer,
        folderIdRenderer: folderIdCellRenderer,
        actionsRenderer: actionsCellRenderer,
    };

    const columnDefs = getFoldersTableColumns(
        hideActions,
        isViewCreateEdit,
        aggregationDisabled,
        user,
    );

    const restProps = isViewCreateEdit ? {
        onRowDataChanged: handleRowDataChanged
    } : {};
    const [selectedRows, setSelectedRows] = useState([]);
    const onRowSelected = (item, isSelected, allSelectedItems) => {
        let updatedSelectedRows;

        if(selectedRows.length > 0)
        {
            if(isSelected)
                {
                    if(!selectedRows.includes(item.asset_folder_id))
                        updatedSelectedRows = [...selectedRows, item.asset_folder_id];
                    else
                        updatedSelectedRows = selectedRows;
                }
            else
                {
                updatedSelectedRows = selectedRows.filter(id => id !== item.asset_folder_id);
                }
            setSelectedRows(updatedSelectedRows);
        }
            else{
                updatedSelectedRows = allSelectedItems.map((objData) => objData.asset_folder_id);
                if(selectedRowIDs.length < updatedSelectedRows.length)
                setSelectedRows(updatedSelectedRows);
            }
        handleRowsSelection('folders', updatedSelectedRows);
    };
    const handleOnPaginationChanged = () => {
        if (selectedRowIDs?.length > 0 && selectedRows?.length <= 0) {
          setSelectedRows(selectedRowIDs);
        }

        gridApi?.forEachNode((node) => {
          if (selectedRows?.includes(node.data.asset_folder_id)) {
            node.setSelected(true);
          }
        });
      };
    return (
        <DataTableGrid
            frameworkComponents={frameworkComponents}
            rowData={tableData}
            columnDefs={columnDefs}
            onRowClicked={handleRowClick}
            pagination
            totalItemsCount={totalCount}
            onPageChange={(pageIndex) => handlePageChange(pageIndex)}
            onPageSizeChange={(newPageSize) => handlePageSizeChange(newPageSize)}
            onRowCheckboxSelected={onRowSelected}
            loading={isLoading}
            onGridReady={handleGridReady}
            {...restProps}
            onPaginationChanged={handleOnPaginationChanged}
        />
    );
};

export default FoldersDataTable;
